import "./Navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { LuLogOut } from "react-icons/lu";
import { RiAdminFill } from "react-icons/ri";

function Navbar() {
    const navigate = useNavigate();
    const [adminRole, setAdminRole] = useState<boolean>(false);
    const location = useLocation();
    const [brand, setBrand] = useState<boolean>(window.innerWidth <= 768);
    const role = sessionStorage.getItem('role');

    const currentPath = location.pathname;

    useEffect(() => {
        if (role === 'Admin' || role === 'Dev') {  // Corrigido
            if (currentPath === '/admin') {
                setAdminRole(true);
            } else {
                setAdminRole(false);
            }
        }
    }, [currentPath, role]);

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    useEffect(() => {
        const handleResize = () => {
            setBrand(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div className="navbar">
                <button className="logo-button" onClick={() => navigate('/')}>
                    <img
                        src={brand ? require("../../assets/brandAZ.png") : "https://grupoazconex.com.br/wp-content/uploads/2024/07/cropped-AZCONEX-LOGO-2048x540.png"}
                        alt="logoAZ"
                        className={brand ? "logo" : "img"}
                    />
                </button>
                {adminRole ? <h1>Página de Admin</h1> : <h1>Simulador de Consórcios</h1>}
                <div>
                    {role === 'Admin' || role === 'Dev' ? (
                        <button onClick={() => navigate('/admin')}>
                            <RiAdminFill />
                        </button>
                    ) : ''}
                    <button onClick={handleLogout}>
                        <LuLogOut />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
