import '../pages/home/Home.css';
import { Input } from './ui/input';

type OptionType = {
    option: string;
};

type SelectModel = OptionType[];
type SelectValue = OptionType[];

interface FormularioProps {
    formData: {
        segmentation: string | undefined;
        income: number | null;
        wishValue: number | null;
        bidValue: number | null;
        type: string;
        isAgro: string;
        selectedOption: 'sim' | 'não' | '';
    };
    onFormChange: (field: string, value: any) => void;
    onSubmit: (data: any) => void;
}

const SimulatorForm: React.FC<FormularioProps> = ({ formData, onFormChange, onSubmit }) => {
    const Options: SelectModel = [
        { option: 'TC - TRATOR E CAMINHAO GERAL' },
        { option: 'IM - IMOVEIS GERAL' },
        { option: 'AI - AUTO IPCA' },
        { option: 'AU - AUTO DEMAIS' },
        { option: 'MO - MOTO DEMAIS' },
        { option: 'EE - OUTROS BENS MOVEIS' },
    ];

    const values: SelectValue = [
        { option: 'TC' },
        { option: 'IM' },
        { option: 'AI' },
        { option: 'AU' },
        { option: 'MO' },
        { option: 'EE' },
    ]


    const formatCurrency = (value: number | null) => {
        if (value === null) return '';
        const numericValue = (value / 100).toFixed(2);
        return `R$ ${numericValue.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <select
                required
                value={formData.segmentation}
                onChange={(e) => onFormChange("segmentation", e.target.value)}
            >
                <option value="" disabled>Informe o Segmento</option>
                {Options.map((segmentation) => {
                    const value = values.find((valor) => valor.option === segmentation.option.split(' - ')[0]);
                    return (
                        <option key={segmentation.option} value={value?.option}>
                            {segmentation.option}
                        </option>
                    );
                })}
            </select>
            <select
                value={formData.isAgro}
                onChange={(e) => onFormChange("isAgro", e.target.value)}
            >
                <option value="" disabled>Área de atuação</option>
                <option value="agro">Agro</option>
                <option value="outros">Outros</option>
            </select>
            {formData.isAgro === 'outros' && (
                <Input
                    placeholder=" Valor da renda comprovada do Cliente"
                    className="currency"
                    required
                    value={formatCurrency(formData.income)}
                    onChange={(e) => onFormChange("income", e.target.value.replace(/\D/g, ""))}
                />
            )}
            <Input
                placeholder="Valor do bem desejado"
                className="currency"
                required
                value={formatCurrency(formData.wishValue)}
                onChange={(e) => onFormChange("wishValue", e.target.value.replace(/\D/g, ""))}
            />
            <select
                value={formData.selectedOption}
                onChange={(e) => onFormChange("selectedOption", e.target.value as 'sim' | 'não')}
            >
                <option value="" disabled>Deseja realizar lance?</option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
            </select>
            {formData.selectedOption === 'sim' && (
                <Input
                    type="text"
                    placeholder="Informe o percentual do lance"
                    value={formData.bidValue ? `${formData.bidValue}%` : ''}
                    onChange={(e) => {
                        const rawValue = e.target.value.replace(/\D/g, '');
                        onFormChange("bidValue", rawValue);
                    }}
                    required
                />
            )}
            <button type="submit">Enviar</button>
        </form>
    );
};

export default SimulatorForm;
