import { Line } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

interface Props {
  months: string[];
  bid: number[];
  winners: number[];
  layout: boolean;
  group: string;
}

const Graph = ({ months, bid, winners, layout, group }: Props) => {

  const realWinners: any = winners[0];

  const dataSet = {
    labels: months,
    datasets: [
      {
        label: `Histórico de Lances e Contemplados Grupo ${group}`,
        backgroundColor: "#193579",
        borderColor: "#193579",
        pointBorderColor: "#193579",
        pointBackgroundColor: "#193579",
        pointBorderWidth: 1,
        pointRadius: 5,
        data: bid,
        fill: false,
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const bidValue = context.raw;
            const formattedBidValue = bidValue.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
            const winnersValue =
              realWinners[context.dataIndex] ?? "Sem dados disponíveis";
            return `Lance: ${formattedBidValue}%, Contemplados: ${winnersValue}`;
          },
        },
      },

      datalabels: {
        display: true,
        clamp: true,
        color: "#193579",
        align: function (context: any) {
          if (context.dataIndex % 2 === 0) {
            return 90;
          } else {
            return 270;
          }
        },
        formatter: function (value: any) {
          const formattedValue = value.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          return `${formattedValue}%`;
        },
        font: {
          size: 10,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 90,
        ticks: {
          stepSize: 5,
          display: false
        },
        grid: {
          display: false
        }
      },
    },
    layout: {
      padding: 10,
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        minWidth: layout ? "375px" : "600px",
        height: "200px",
      }}
    >
      <Line data={dataSet} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default Graph;
