import '../pages/home/Home.css';
import { Input } from './ui/input';
import { Message } from './message/Message';
import { useState } from 'react';

interface SearchProps {
    group: number | null;
    setGroup: (group: number | null) => void;
    onSearchSubmit: (data: { group: number; type: string }) => void;
}

const Search: React.FC<SearchProps> = ({ group, setGroup, onSearchSubmit }) => {
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (group !== null) {
            onSearchSubmit({
                group: group,
                type: 'search',
            });
        } else {
            setError(true);
            setMessage('Insira um grupo, por favor.');
            setTimeout(() => {
                setError(false);
            }, 5000);
        }
    };

    return (
        <div className="search">
            <form onSubmit={handleSubmit}>
                <label>Informe aqui o número do grupo</label>
                <Input
                    placeholder="Número do Grupo"
                    type="number"
                    value={group !== null ? group.toString() : ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        setGroup(value ? Number(value) : null);
                    }}
                />
                <button type="submit">Pesquisar</button>
                {error && <Message msg={message} type="information" />}
            </form>
        </div>
    );
};

export default Search;
